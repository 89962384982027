import { Grid } from '@mui/material';
import type { NextPage } from 'next';
import dynamic from 'next/dynamic';
import { memo } from 'react';

import Apresentation from '../components/Home/Apresentation';
import Line from '../components/Line';
const Welcome = dynamic(() => import('../components/Home/Welcome'));
const DevyxResolve = dynamic(() => import('../components/Home/DevyxResolve'));
const Diferential = dynamic(() => import('../components/Home/Diferential'));
const Evolution = dynamic(
  () => import('../components/Home/Evolution/Evolution'),
);
const Boost = dynamic(() => import('../components/Home/Boost/Boost'));

const Home: NextPage = () => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      gap={'40px'}
    >
      <Welcome />
      <DevyxResolve />
      <Apresentation
        title={'Precisa automatizar cobranças de clientes?'}
        subtitle={
          'Lembrá-los de que o boleto está para vencer? Ou atuar para redução de inadimplência, com rapidez e segurança? Devyx resolve.'
        }
        path={'trader'}
      />
      <Apresentation
        title={'Quer melhorar sua captura de leads?'}
        subtitle={
          'Construa landing pages de forma fácil e rápida, deixe-as a cara da sua marca e irresistíveis a seus clientes-alvo. A Devyx auxilia você!'
        }
        path={'buildr'}
        reverse
      />
      <Apresentation
        title={
          'Precisa de uma plataforma intuitiva para gerenciar disparos de SMS de seus clientes?'
        }
        subtitle={
          'Quer expandir suas vendas sem preocupação com infraestrutura? Devyx também pode te ajudar.'
        }
        path={'zoug'}
      />
      <Line />
      <Diferential />
      <Boost />
      <Evolution />
    </Grid>
  );
};

export default memo(Home);
