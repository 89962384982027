import Grid from '@mui/material/Grid';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { FunctionComponent } from 'react';

import trader from '../../lotties/100216-trader.json';
import zoug from '../../lotties/100415-dashboard.json';
import buildr from '../../lotties/100416-landpage.json';
import imgBuildr from '../../public/img/buildr.webp';
import imgTrader from '../../public/img/trader.webp';
import imgZoug from '../../public/img/zoug.webp';
import Button from '../Buttons/Button';
import useBreakpoints from '../Hooks/useBreakpoints';

const Gif = dynamic(() => import('../LazyLottie'));

const gifs = {
  trader,
  zoug,
  buildr,
};

const images = {
  zoug: imgZoug.src,
  trader: imgTrader.src,
  buildr: imgBuildr.src,
};

const Apresentation: FunctionComponent<{
  reverse?: boolean;
  path: string;
  title: string;
  subtitle: string;
}> = ({ reverse, path, title, subtitle }) => {
  const { isDesktop } = useBreakpoints();
  return (
    <Grid
      container
      item
      flexDirection={{ xs: 'column', lg: reverse ? 'row-reverse' : 'row' }}
      justifyContent={{ xs: 'center', lg: 'flex-start' }}
      alignItems={{
        xs: 'flex-start',
        sm: 'center',
        md: 'center',
        lg: 'center',
      }}
      xs={12}
      gap={{ xs: '24px', lg: '0px' }}
    >
      <Grid
        container
        item
        direction="column"
        justifyContent={'flex-start'}
        alignItems={'flex-start'}
        xs={12}
        lg={5}
        spacing={4}
      >
        <Grid
          item
          container
          direction="row"
          justifyContent={'flex-start'}
          alignItems={'center'}
          className="barlow--size32 bold"
          gap={'16px'}
        >
          <Grid
            item
            width={{ xs: '56px', lg: '80px' }}
            height={{ xs: '56px', lg: '80px' }}
          >
            <Image
              src={images[path]}
              alt={'seta'}
              width={isDesktop ? 80 : 56}
              height={isDesktop ? 80 : 56}
            />
          </Grid>
          <Grid item>{path}</Grid>
        </Grid>
        <Grid item className="barlow--size32 bold">
          {title}
        </Grid>
        <Grid item className="open-sans--size18">
          {subtitle}
        </Grid>
        <Grid item>
          <Button href={`/${path}`}>
            Conheça {path[0].toUpperCase()}
            {path.substring(1)}
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={7}>
        <Gif data={gifs[path]} />
      </Grid>
    </Grid>
  );
};

export default Apresentation;
